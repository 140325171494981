import Title from "Components/Title";
import { FC } from "react";
import { Wrapper } from "./Author.styled";

type Props = {
  isAuthor: boolean;
  name: string;
  image: string;
};

const Author: FC<Props> = ({ isAuthor, name = "", image = "" }) => {
  if (!isAuthor) {
    return null;
  }
  return (
    <Wrapper>
      <Title type="author">
        Articles by <span>{name}</span>
      </Title>
    </Wrapper>
  );
};

export default Author;
