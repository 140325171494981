import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 77px;
  img {
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
`;
